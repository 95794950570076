import gql from 'graphql-tag'
import {getCounterpartyId} from '../../../services/authService'

export const getMyClientsQuery = gql`
	query {
		allClients(tenantId: "${getCounterpartyId()}") {
			tenantId
			clientId
			isActive
			fullName
			shortName
			legalEntityExternalId
			externalProviderRefId
			clientRanking
			clientType
			internalCreditRating
			country
			orgId
			entityType
			entitySubType
			relationships
      internalCode1
      internalCode2
      internalCode3
      internalCode4
      internalCode5
			limits {
				clientLimitsId
				id
				limitsCategory
				limitsType
				assetType
				limitsValue
				expiry
			}
			durationLimits {
				durationLimitsId
				id
				assetType
				days
				expiry
			}
      agreementTypes
      clientTypeDesc
			properties {
				propertyId
				id
				agreementType
				propertyKey
				propertyValue
				propertyDataType
			}
		}
	}
`
