import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import queryString from 'query-string'
import CryptoJS from 'crypto-js'
import {ArrayUtil, FormatUtil} from 'helper-util'
import {globalColumnDefinition} from '../../common/AgGrid/AgGridColumn'
import {commaTwoDecimal, noCommaTwoDecimal, noPinnedFooter} from '../../common/AgGrid/AgGridHelper'
import {aggFuncSum, pivotParams, urlEncryptionKey} from './abcPortfolioConstant'

export const getColumnDefs = memoizeOne((defaultColumn: boolean, isFosColumn) => {
	const columnDefs = [
		Object.assign({}, globalColumnDefinition.activityType, {
			cellRenderer: params => {
				const {value, data} = params
				const validValues = [
					'n/a',
					'new borrow',
					'new loan',
					'reverse',
					'repo',
					'new reverse',
					'new repo',
					'tsy buy',
					'tsy sell',
					'cp sell',
					'cp buy',
				]
				const lowerCaseValue = FormatUtil.text.toLowerCase(value)
				const notClickable = validValues.includes(lowerCaseValue)

				// eslint-disable-next-line no-restricted-globals
				if (window !== top) {
					return notClickable
						? value
						: data
						? `<span class='text-link' onclick='return inlineStorageToPopup("/activity-type/${params.data.id}")'>${value}</span>`
						: ''
				} else {
					return notClickable
						? value
						: data
						? `<span class='text-link' onclick='return window.open("/activity-type/${params.data.id}")'>${value}</span>`
						: ''
				}
			},
			...pivotParams,
		}),
		Object.assign({}, globalColumnDefinition.partial, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.portfolioEntryType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.securityId, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.cusip, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.securityIdType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.priceAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.priceCurrency, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.notionalCurrency, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.notionalAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.counterParty, {...pivotParams}),
		globalColumnDefinition.haircut,
		Object.assign({}, globalColumnDefinition.referenceRate, {...pivotParams}),
		globalColumnDefinition.spread,
		Object.assign({}, globalColumnDefinition.allInRate, isFosColumn ? noCommaTwoDecimal : {}),
		Object.assign({}, globalColumnDefinition.tradeType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.buySellInd, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.maturityType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.startDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.endDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.tradeDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.settlementDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.createdBy, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.createdDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.book, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.sourceSystem, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.sourceSystemTradeId, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.isin, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.ticker, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.originalTradeType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.legalEntity, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.assetType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.assetSubType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.moodyRating, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.snpRating, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.fitchRating, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.assetMaturityDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.impliedAllInRate, isFosColumn ? noCommaTwoDecimal : {}, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.assetClassification, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.inefficienciesArray, {...pivotParams}),
	]

	const defaultColumnDefs = [
		Object.assign({}, globalColumnDefinition.actions, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.isRestricted, {...pivotParams}),
		// Object.assign({}, globalColumnDefinition.inefficienciesArray, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.balanceSheetContributionAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.lcrNotionalAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.lcrCost, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.lcrHaircut, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.lcrHqla, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.assetBasedCharge, {
			...aggFuncSum,
		}),
		Object.assign({}, globalColumnDefinition.cofReferenceRate, {
			...aggFuncSum,
			...commaTwoDecimal,
			filter: true,
			filterType: 'number',
			filterParams: {
				valueFormatter: commaTwoDecimal.cellRenderer,
			},
			...noPinnedFooter,
		}),
		Object.assign({}, globalColumnDefinition.priceMultiplier, {...aggFuncSum}),
		globalColumnDefinition.exchangeRate,
		Object.assign({}, globalColumnDefinition.recallInefficiencyContributionAmount, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.returnInefficiencyContributionAmount, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.collateralType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.collateralAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.rebate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.borrowFee, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.loanFee, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.maturityDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.termDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.fund, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.ric, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.bbId, {...pivotParams}),
		// Object.assign({}, globalColumnDefinition.assetClassification, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.sector, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.index, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.poolFactor, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.allInPriceAmount, isFosColumn ? noCommaTwoDecimal : {}, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.tradeCurrency, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.depot, {...pivotParams}),
	]

	const fosColumnDefs = [
		Object.assign({}, globalColumnDefinition.quantity, {headerName: 'Quantity/Principal', ...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.startCash, {
			field: 'startCashAmount',
			headerName: 'Start Cash/Contract Value',
			...aggFuncSum,
		}),
		Object.assign({}, globalColumnDefinition.endCash, {field: 'endCashAmount', ...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.callable, {
			cellRenderer: params => {
				return params.value === true ? 'Y' : 'N'
			},
			...pivotParams,
		}),
		Object.assign({}, globalColumnDefinition.puttable, {
			cellRenderer: params => {
				return params.value === true ? 'Y' : 'N'
			},
			...pivotParams,
		}),
		Object.assign({}, globalColumnDefinition.callableDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.puttableDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.proceedsAmount, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.parAmount, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.comments, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.dailyInterestPayable, {...pivotParams, ...commaTwoDecimal}),
		Object.assign({}, globalColumnDefinition.dailyInterestReceivable, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.actions, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.discountRate, isFosColumn ? noCommaTwoDecimal : {}, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.discountAmount, {...pivotParams}),
	]

	const nonFosColumnDefs = [
		Object.assign({}, globalColumnDefinition.quantity, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.startCash, {field: 'startCashAmount', ...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.contractValue, {...pivotParams}),
	]

	return [
		...columnDefs,
		...(defaultColumn ? defaultColumnDefs : []),
		...(isFosColumn ? fosColumnDefs : nonFosColumnDefs),
	]
}, isDeepEqual)

export const getRowData = memoizeOne(data => {
	const rowData = []
	!ArrayUtil.isEmpty(data) &&
		data.forEach(item => {
			const rowDataObj = JSON.parse(JSON.stringify(item))
			rowDataObj.allInPriceAmount = rowDataObj.allInPrice ? rowDataObj.allInPrice.amount : 0
			rowDataObj.disableCheckbox = !Boolean(item.actions)
			rowData.push(rowDataObj)
		})
	return rowData
}, isDeepEqual)

export const getRowDataWithSpreadUpdated = memoizeOne(data => {
	const rowData = []
	!ArrayUtil.isEmpty(data) &&
		data.forEach(item => {
			const rowDataObj = JSON.parse(JSON.stringify(item))
			rowDataObj.spread = rowDataObj.spread * 100 || rowDataObj.spread
			rowData.push(rowDataObj)
		})
	return rowData
}, isDeepEqual)

export const formatFilters = memoizeOne(filtersArray => {
	const formattedArray = filtersArray.map(item => {
		if (item === null) {
			return 'N/A'
		} else {
			return item
		}
	})

	return !ArrayUtil.isEmpty(formattedArray) ? ['View All', ...formattedArray] : []
}, isDeepEqual)

export const getCreateBorrowConfirm = (selectedData: any) => {
	return [
		{key: 'Allocated Notional', value: selectedData.allocatedNotional},
		{key: 'Allocated Quantity', value: selectedData.allocatedQuantity},
		{key: 'Collateral Type', value: selectedData.collateralType},
		{key: 'Counterparty Notional', value: selectedData.counterPartyNotional},
		{key: 'Counterparty Quantity', value: selectedData.counterPartyQuantity},
		{key: 'Currency', value: selectedData.currency},
		{key: 'Exchange Rate', value: selectedData.exchangeRate},
		{key: 'Fee Rate', value: selectedData.feeRate},
		{key: 'Name', value: selectedData.name},
		{key: 'Needs Notional', value: selectedData.needsNotional},
		{key: 'Needs Quantity', value: selectedData.needsQuantity},
		{key: 'Price', value: selectedData.price},
		{key: 'Rebate Rate', value: selectedData.rebateRate},
		{key: 'Security Id', value: selectedData.securityId},
	]
}

export const getSingleSecurityCreateBorrowDetails = (dataCreateBorrow: any) => {
	return {
		details: getCreateBorrow(dataCreateBorrow[0]).map(item => {
			return {
				...item,
				allocatedNotional: item.allocatedQuantity * item.price * item.exchangeRate,
			}
		}),
	}
}

export const getCreateBorrow = (selectedData: any) => {
	return [
		{
			allocatedNotional: selectedData.allocatedNotional,
			allocatedQuantity: selectedData.allocatedQuantity,
			collateralType: selectedData.collateralType,
			counterPartyNotional: selectedData.counterPartyNotional,
			counterPartyQuantity: selectedData.counterPartyQuantity,
			currency: selectedData.currency,
			exchangeRate: selectedData.exchangeRate,
			feeRate: selectedData.feeRate,
			id: selectedData.id,
			isin: selectedData.isin,
			name: selectedData.name,
			needsNotional: selectedData.needsNotional,
			needsQuantity: selectedData.needsQuantity,
			price: selectedData.price,
			rebateRate: selectedData.rebateRate,
			securityId: selectedData.securityId,
		},
	]
}

export const decryptURL = memoizeOne((data: any) => {
	if (typeof data === 'string' && data.length > 1) {
		const decryptedBytesData = CryptoJS.AES.decrypt(data.substring(1), urlEncryptionKey)
		const decryptedValue = decryptedBytesData.toString(CryptoJS.enc.Utf8)
		if (decryptedValue) {
			return JSON.parse(decryptedBytesData.toString(CryptoJS.enc.Utf8))
		}
		return queryString.parse(data)
	}
	return data
}, isDeepEqual)

export const encryptURL = memoizeOne((data: any) => {
	return CryptoJS.AES.encrypt(JSON.stringify(data), urlEncryptionKey).toString()
}, isDeepEqual)

export const noFiltersApplied = (filters: any) => {
	const {groupOne, groupTwo} = filters
	return (!groupOne.value || groupOne.value === 'View All') && (!groupTwo.value || groupTwo.value === 'View All')
}

export const removeNullsAndSort = memoizeOne((filterValues: any) => {
	return filterValues.filter(item => item !== null).sort()
}, isDeepEqual)
